import React from 'react';

export function InvalidUserAlert() {
  return (
    <div class="p-5 m-5 d-flex flex-column justify-content-center align-items-center deactivated-user">
        <div class="d-flex align-item-center justify-content-center accdeactivate">
            <div>
                <img src={`${process.env.PUBLIC_URL}/images/activehub-logo-tablet.svg`} alt="Invalid subscription" />
            </div>
            <div class="rightcnt">
                <p class="fontModel-hc pl-4">Invalid subscription</p>
                <p class="ml-4 mdlcnt">
                Your trust does not have the required subscription to access Dashboard. Please <span class="font-italic"><strong>contact your Trust administrator.</strong></span>
                </p>
            </div>
        </div>
    </div>
  )
}
