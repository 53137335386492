import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import './SpaWrapper.scss';
import { Routes } from '../../components'
import {spaSTGConfig,spaProdConfig,spaQAConfig} from '../../constants/Config'
import  Home  from '../dashboard/Home';
import SchoolList from '../schoolList/SchoolList'
import UserWrapper from '../UserWrapper/UserWrapper';
import MicroFrontend from '../../components/micro-frontend/MicroFrontend';
import { useUpdateEffect } from '../../utils/CustomHooks';
import { Loader } from "../../components/loader/Loader";
import { setSelectedSpaId} from "../../actions/userAction";
import {  
  PageNotConfigured,
  UserNotEntitled
} from "common-components-spa";
import { adminLinkConsts } from "../../constants/AdminLink";
import {DeactivatedUserAlert} from '../deactivated-user-alert/DeactivatedUserAlert'
import {InvalidUserAlert} from '../deactivated-user-alert/InvalidUserAlert'


export function SpaWrapper(props) {
  const { params: { applicationId: encodedApplicationId }, path: matchPath, url: matchURL } = useRouteMatch();
  console.log(useRouteMatch)
  const {prodHostname,stgHostname} = adminLinkConsts
  const spaConfig = window.location.hostname === prodHostname ? spaProdConfig : window.location.hostname === stgHostname ? spaSTGConfig :spaSTGConfig
  const userId = useSelector(state => state.user.userId);
  const selectedSpaId = useSelector(state => state.user.selectedSpaId);
  const isActive = useSelector(state => state.user.isActive);
  const [loadingDone, setLoadingDone] = useState(false);  
  const history = useHistory();
  const dispatch = useDispatch();
  const [spaState, setSPAState] = useState({
        name: '',
        host: ''
    });
    const [mfState, setMfState] = useState(false);
    const location = useLocation()
    let fullPath = location.pathname.split('/');
    let modulePath = fullPath[2];
    

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const redirect = query.get('switch');
    console.log(redirect,'params spa wrAPPE')

    console.log('new->',modulePath ,'exist->', localStorage.getItem('defaultSpaId'))
    const applicationId = typeof modulePath == 'undefined' ? 'dashboard' : (localStorage.getItem('defaultSpaId') === modulePath) ? localStorage.getItem('defaultSpaId') : 'dashboard'
   useEffect(() => {
    setLoadingDone(true)
    // setSPAState({
    //     name: 'Trust insights',
    //     host: insightsappHost
    // });
  }, []); 

  const updateSpaState = (id) => {
    const spa = spaConfig.find(spa => spa.spaId === id);
    setSPAState({
        containerId: spa?.spaId,
        name: spa?.name,
        host: spa?.launchPath
    });
    setMfState(true);
};

useEffect(() => {
  window.Appcues && window.Appcues.page();
}, [location])



  useEffect(() => {
    const foundSpa = applicationId && applicationId != null && spaConfig.find(spa => spa.spaId === applicationId);
     if (!foundSpa && selectedSpaId && spaConfig.length > 0 && applicationId !== "yetToBeCreated" &&  applicationId !== "notificationPage") {
      localStorage.setItem('defaultSpaId', selectedSpaId);   
        if(selectedSpaId == "dashboard" && redirect == null){
            history.push("/application/dashboard");
          }else if(selectedSpaId == "schoolInsights"){
            history.push("/application/schoolInsights");
          }
          else if(selectedSpaId == "userProfile"){
            history.push("/application/userProfile");
          }else{
            const defaultSpaId = selectedSpaId;
           // const newEncodedApplicationId = encodeURIComponent(defaultSpaId);
            dispatch(setSelectedSpaId(defaultSpaId));
            sessionStorage.setItem('spaId', defaultSpaId);           
            // setIframeLevelErrorPage(null);
            const newUrl = `/application/${selectedSpaId}/`;
            updateSpaState(selectedSpaId);
            if(redirect == null)
            history.push(newUrl);
          }
         

         } else {
                  /**
         * If the applicationId from URL is not same as selected SPA id,
         * set the selected SPA id same as application id.
         * This is used to handle reload state, when selectedSpaId is reset to default.
         */
        dispatch(setSelectedSpaId(applicationId));
        localStorage.setItem('defaultSpaId', applicationId);
        updateSpaState(applicationId);
       // setUrlApplicationId(applicationId)
        sessionStorage.setItem('spaId', applicationId);
    }
}, [spaConfig])

useUpdateEffect(() => {
  if (applicationId != selectedSpaId) {
       localStorage.setItem('defaultSpaId', selectedSpaId);
      setMfState(false);
      //const newEncodedApplicationId = encodeURIComponent(selectedSpaId);
      //const newUrl = matchPath.replace(':applicationId?', newEncodedApplicationId);
      const newUrl = `/application/${selectedSpaId}/`;
      history.push(newUrl);
      updateSpaState(selectedSpaId);
  }
}, [selectedSpaId]);

useEffect(() => {
  const prodAssessmentSPAId = 'urn:pearson:gps:spa:d051717f-cb63-4563-969c-da8032901aeb';
  const nonProdAssessmentSPAId = 'urn:pearson:gps:spa:4a3b2922-518b-4a6d-b18e-45f4b1b047dd';
  if (selectedSpaId == nonProdAssessmentSPAId || selectedSpaId == prodAssessmentSPAId) {
    document.body.classList.add('assessment-dashboard');
  } else {
    document.body.classList.remove('assessment-dashboard');
  }
}, [selectedSpaId]);

  window.GATEWAY = Object.assign(window.GATEWAY || {}, {
    containerId: selectedSpaId,
    baseName: `/application/${selectedSpaId}`
  });

const microFrontend = useCallback(() => {
    console.log(spaState,'spaState')
    if (spaState.host && mfState) {
        return <MicroFrontend {...spaState} />
    } 
}, [spaState])

useEffect(() => {
  let userID = Math.random()
  console.log("userID appcues", userID)
  console.count(userID)
  window.Appcues && window.Appcues.identify(userId);
}, [])

  const routes = useMemo(() => [
         {
            component: Home,
            path: "/application/dashboard",
            exact: false,
            title: "Trust Wrapper",
        },
        {
          component:UserWrapper,
          path:"/application/userProfile",
          exact: false,
            title: "Trust Wrapper",
        },
        {
          component: SchoolList,
          path: "/application/schoolInsights",
          exact: false,
          title: "Trust Wrapper",
      },
        {
            component: () => (microFrontend()),
            path: "/application/:applicationId?",
            exact: false,
            title: "Trust Wrapper",
        },

        ], [microFrontend])

 
   if (!loadingDone) {
            return (
              <Loader />
            );
          }
  
const defaultRoute = routes[0].path;
console.log(defaultRoute ,'defaultRoute', routes)
if(props.errorCode == 403){
  return <div className="user-not-entitled-wrapper page-not-config">
  <UserNotEntitled />
</div>;
}else if(props.errorCode == 404){
  return <div className="page-not-config">
  <PageNotConfigured />;
</div>;
}else{
  if(isActive == false){
    return <div className="page-not-config"><InvalidUserAlert /></div>;   
  }else{
    return(
      <div className="gwSpaWrapper">
         <div className="gwSpaWrapper__iframe">
               <Routes routes={routes} defaultRoute={defaultRoute} />
      </div>
  </div>
    )
  }
 
}
 
  
}