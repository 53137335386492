import React from "react";
import { useHistory } from 'react-router-dom';

import "./gridcard.scss";

const GridCard = ({ nav,changeApplication }) => {

  const history = useHistory();
 
  /**
   * handle click handler of spa
   */
  const onClickHandler = () => {
    if (nav.link instanceof Function) {
      console.log('12345')
      /**
       * if link type is of function that mean we have to
       * generate the admin link and redirect to admin
       */
      nav.link();
    } else if (nav?.externalLink) {
      console.log('5676767')
      /**
       * if external link set then redirect to the set URL
       */
      window.open(nav?.externalLink);
    } else {
      console.log('hre');
      changeApplication(nav.spaId, nav.name);
    }
  };

  return (
       <div className='homeicons' onClick={onClickHandler} tabIndex="0">
        <span className="gridBdr" />
          <img src={nav.imageUrl} alt={nav.hd} />
          
          <div className="gridHd">{nav.hd}</div>
          {nav.info}
      </div>
   );
};

export default GridCard;
