export const adminLinkConsts = {
    prodHostname : "activehub-trust.pearson.com",
    prodGatewayHostname: "homepage.pearson.com",
    prodPlatformId : "urn:pearson:gps:platform:cf0fd3de-dfd3-42e4-8fcf-a8f0edbce00a",
    prodGatewaySpaId: "urn:pearson:gps:spa:89c6e5d7-f746-4936-8d61-ea7c2c17ec6a",
    stgHostname: "stg.pet.ift.pearson-intl.com",
    stgGatewayHostname: "stg-gb.ggw.ift.pearson-intl.com",
    stgGatewaySpaId: "urn:pearson:gps:spa:592a83d4-c867-44bd-8313-0cc215fd66b6",
    stgPlatformId : "urn:pearson:gps:platform:62a5fc19-4fa4-452f-938c-47720fca6711",
    qaGatewayHostname: "qa-gb.ggw.ift.pearson-intl.com",
    qaGatewaySpaId: "urn:pearson:gps:spa:bdd514e0-479f-4fac-8e54-a591d29ccaf4",
    qaPlatformId : "urn:pearson:gps:platform:2c0bf5e9-50fb-4486-aef2-9a03f2866620",
    devPlatformId : "urn:pearson:gps:platform:454ad097-0ebb-430f-adee-323570a8ddd1",
    perfGatewayHostname: "perf-gb.ggw.ift.pearson-intl.com",
    perfGatewaySpaId: "urn:pearson:gps:spa:0935b628-e82a-4e5d-b378-da295db60a22" 
   
}

