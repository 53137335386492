  
import React,{useMemo,useEffect} from 'react';
import { useDispatch,useSelector } from "react-redux";
import {  useRouteMatch} from "react-router-dom";
import {HomeSTGLinks,HomeProdLinks,HomeQALinks} from '../../constants/Config'
import admin from '../../images/icon-admin.png';
import helphub from '../../images/icon-helphub.png';
import ahschool from '../../images/icon-ahschool.png';
import { adminLinkConsts } from "../../constants/AdminLink";
import { baseHelpPageUrl } from "../../constants/HelpLink";
import GridCard from "./GridCard/GridCard";
import {setSelectedSpaId} from "../../actions/userAction";
import {handleLoginLogoutEvents ,handlePageviewEvent} from '../../utils/autobahn/autobahnHandler'

const Home = () => {
  const { path: selectedPath } = useRouteMatch();
  const {prodHostname,stgHostname} = adminLinkConsts
  const homeLinks = window.location.hostname === prodHostname ? HomeProdLinks : window.location.hostname === stgHostname ? HomeSTGLinks :HomeSTGLinks
  const userDetail = useSelector(state => state.user.userDetails);
    const selectedSpaId = useSelector(state => state.user.selectedSpaId);
    const dispatch = useDispatch();
  /**
   * create admin spa link
   */
  /**pageview event code */
  useEffect(() => {
    const iesEvent = window.dataLayer?.length && window.dataLayer?.some(data => data?.person_id_type?.toLowerCase() === "ies");
    const loginEvent = localStorage.getItem("autobahnLogin");
    console.log(iesEvent,'iesEvent',loginEvent)
    if (iesEvent && !localStorage.getItem("dashboardLoaded")) {
      handlePageviewEvent("dashboard");
      localStorage.setItem("dashboardLoaded", true);
      if (!loginEvent) {
        handleLoginLogoutEvents("login");
        localStorage.setItem('autobahnLogin', true);
      }
    }
  },[selectedSpaId, window.dataLayer]);
  
  /**event code ends */
  const adminLinkClickHandler = (e) => {
    //e?.stopPropagation();
    let {
      prodHostname,
      stgGatewayHostname,
      stgGatewaySpaId,
      prodGatewayHostname,
      prodGatewaySpaId,
      qaGatewaySpaId,
      qaGatewayHostname,
      stgHostname,
    } = adminLinkConsts;
    let gatewayhostname, gatewaySpaId;
    if (window.location.hostname === prodHostname) {
      gatewayhostname = prodGatewayHostname;
      gatewaySpaId = prodGatewaySpaId;
    } else if (window.location.hostname === stgHostname) {
      gatewayhostname = stgGatewayHostname;
      gatewaySpaId = stgGatewaySpaId;
    } else {
      gatewayhostname = qaGatewayHostname;
      gatewaySpaId = qaGatewaySpaId;
    }
    const adminUrl = `https://${gatewayhostname}/#/application/${gatewaySpaId}/user-list`;
    const adminTab = window.open(adminUrl);
    adminTab.history.pushState(
      null,
      "Pearson Global Gateway-Admin-User accounts",
      adminUrl
    );
  };

  const changeApplication = (spaId, spaName) => {
    if (spaName?.toLowerCase() === "insights") {
      dispatch(setSelectedSpaId(''));
      setTimeout(() => { 
        dispatch(setSelectedSpaId(spaId));
      }, 0);
    } else {    
      dispatch(setSelectedSpaId(spaId));
    }
  };

   /**
   * Create nav data from 
   * - admin and help link statically
   * - Other from spa configuration API
   */

  const navData = useMemo(() => {
    const staticNavItems = [
      {
        imageUrl :admin,
        info:'User and data access management.',
        hd:'Trust Admin',
        link: adminLinkClickHandler,
      },
      {
        imageUrl: helphub,
        info:'Tips and tutorials on using ActiveHub Trust.',
        hd:'Helphub',
        link: "",
        externalLink: baseHelpPageUrl,
      }
    ];

    if(!userDetail.isValid){
      staticNavItems.splice(0,1)
    }
    return userDetail.isValid ? [...homeLinks, ...staticNavItems] :[ ...staticNavItems] ;
  }, [homeLinks]);


  return (
    <div className={`container homepage ${!userDetail.isValid ? 'no-padding':''}`} >
        {
          userDetail.isValid != null && !userDetail.isValid && <div className='no-subscription'>
                <div className='no-subscription-rectangle'>
                    <span> User does not have adequate access privileges</span>
                  </div>
            </div>
        } 
  <h1>Home</h1>
  <h4>{userDetail?.orgGroupName ? userDetail.orgGroupName+' -':''}  Welcome back {userDetail.firstName} {userDetail.lastName}! </h4>
  <div className='homeicons-row'>
    {
      navData.map((links) => {
        return <GridCard nav={links}  changeApplication={changeApplication}/>
      })
    }     
  </div>
</div>
  )
}

export default Home;