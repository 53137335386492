/**
 * functions for handling
 * event tagging
 */
import { getUuid, getDateISOString, getLocalTimestamp } from "./commonAutobahnUtilities";

const COMMON_EVENT_VALUES = { product_platform_code: "ActiveHub Trust", product_model_name: "Active Hub Secondary", business_model_code: "directToConsumer"};

const PAGEVIEW_PAGE_LABELS = {
    dashboard : "Activehub_Trust_Dashboard"
}

const getPageviewData = (page) => {
    return {
        event: "pageview",
        page_title: PAGEVIEW_PAGE_LABELS[page],
        product_id: getUuid(), // Product Id as UUID
        ...COMMON_EVENT_VALUES,
        course_id: null, // Course Id
        course_section_id:null, // Course Section Id
        content_id: null, // Content Id
        content_id_type: null, // Content Id Type
        message_id: getUuid(), // Message Id. Should be in UUID Format
        transaction_dt: getDateISOString(), // Transaction Date in ISO Format
        transaction_local_dt: getLocalTimestamp() // Local Timestamp in Format: YYYY-MM-DDThh:mm:ss.ms
    }
}

const getLoginLogoutData = (isLoginEvent) => {
    return {
        event: isLoginEvent ? "login" : "logout",
        event_category: "AppInteraction",
        event_action: isLoginEvent ? "Login" : "Logout",
        event_label: null,
        transaction_local_dt: getLocalTimestamp(),
    }
}

export const handlePageviewEvent = (page) => {
    window.dataLayer = window.dataLayer || [];
    let pageviewData = getPageviewData(page);
    window.dataLayer.push(pageviewData); 
}

export const handleLoginLogoutEvents = (eventName) => {
    const isLoginEvent = eventName?.toLowerCase() === "login";
    const loginLogoutData = getLoginLogoutData(isLoginEvent);
    console.log(loginLogoutData,'loginLogoutData')
    window.dataLayer.push(loginLogoutData);
}