import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  Select,
  Button,
  useValidation,
  Loader
  } from "common-components-spa";

import { AppContext } from "../../App";

// import UploadPic from "../../components/UploadPic/UploadPic";
import { updateUserProfile, getUserRole } from "../../network/ApiService";
import {setSelectedSpaId} from "../../actions/userAction";
import {decode} from 'html-entities';
// css for user profile
import "./UserProfile.scss";

const UserProfile = (props) => {
  const dispatch = useDispatch();
  const regionId =useSelector(state => state.user.region);
  const userId = useSelector(state => state.user.userId);
  const selectedSpaId = useSelector(state => state.user.selectedSpaId);
  console.log(userId,regionId,'userprofile inside')
  const {
    handleAPIError,
    popupConfig: { toastMessage, setLoaderState },
  } = props
  const userProfile =useSelector(state => state.user.userDetails);
 // const userDetail = useSelector(state => state.user.userDetails)
  console.log(userProfile);
  const history = useHistory();
  const { params: { applicationId: encodedApplicationId }, path: matchPath, url: matchURL } = useRouteMatch();

  // initial user object
  const initUser = {
    firstName: userProfile?.firstName,
    lastName: userProfile?.lastName,
    emailId: userProfile?.email,
    userName: userProfile?.userName,
    roles: "",
    organization: '',
    orgGroupName:userProfile?.orgGroupName,
    userId:userId
  };

  const [userData, setUserData] = useState(initUser);
  const [initailState, setInitailState] = useState(initUser);
  const [isloading, setLoading] = useState(false);

  const handlePasswordPopUp = () => {
    props.handlePasswordPopupState(true)
  }

  const handleBackButton = () => {
    dispatch(setSelectedSpaId('dashboard'));
  };

  useEffect(() => {
    if (!regionId) return;
    setLoading(true);

    // Getting the ROLE of user
    const subscription = getUserRole({
      filter: {
        userId,
        regionId: regionId,
      },
    }).subscribe(
      (response) => {
        const PMRIDs = ["urn:pearson:gps:productmodelregion:e0fe76ad-bc5a-4dc1-8c7c-caf636e7e2a3",
    "urn:pearson:gps:productmodelregion:9b92df41-f614-408a-8e49-d8a255a7b52a"];
        if (response && response[0]) {
          let roles = Array.prototype.map.call(
            response[0]?.roles,
            function (item) {
              if (
                item.organizationId != null && response[0]?.organizations[0]?.orgId === item.organizationId
              ) {
                return item.role;
              }
            }
          );

          roles = roles.filter((n) => n).join(", ");
          const organisationList = response[0]?.organizations || [];
          let orgsList = []
          if (organisationList.length) {
             orgsList = organisationList.reduce((filtered, object) => {
                const pmrs = object.productModelRegion.filter(ele => PMRIDs.includes(ele))
                if (pmrs?.length) {
                    const newOrgObject = { ...object };
                    filtered.push(newOrgObject);
                }
                return filtered;
            }, []);
        }

          // setting to state
          setUserData({
            ...userData,
            organization: orgsList && orgsList[0],
            roles: roles,
          });
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );

    return () => subscription.unsubscribe();
    // dont need to run this effect when setters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // validate object
  const isValid = useValidation(userData, ["firstName", "lastName", "emailId"]);

  // handle input change
  const handleInput = (e) => {
    const {
      target: { value, name },
    } = e;

    // setting to state
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const checkChange = useCallback(() => {
    const { firstName, lastName, emailId } = userData;

    if (
      !isValid ||
      (firstName === initailState?.firstName &&
        lastName === initailState?.lastName &&
        emailId === initailState?.emailId)
    ) {
      // checking if form is not valid &&
      // if value is not change
      return false;
    }

    return true;
  }, [userData, initailState, isValid]);


  // form submit
  const updateUser = () => {
    if (isValid) {
      setLoaderState(true);

      updateUserProfile(userProfile?.userId, { emailId: userData.emailId, firstName: userData.firstName, lastName: userData.lastName }).subscribe(
        (response) => {
          console.log("user updated", response);
          toastMessage({
            msg: "<strong>User</strong> updated Successfully !!!",
            success: true,
          });
          // setting the updated state to initail
          setInitailState(userData);
        },
        (error) => {
          handleAPIError({
            error,
            callback: updateUser,
          });
        },
        () => {
          setLoaderState(false);
        }
      );
    }
  };
  const {
    firstName,
    lastName,
    emailId,
    userName,
    orgGroupName
  } = userData;

  if (isloading) {
    return <Loader />;
  }

  return (
    <>
      <div className="col-12 col-md-12 col-lg-8 mx-auto px-0 mt-3">
        <div className={`gsam-table-blk mt-3`}>
          <div className="cardinfo">
            <div className="cardinfo__head">
              <div className="d-flex align-items-center justify-content-between">
                <div className="col-6 p-0">
                  <h3 className="cardinfo__head__txt">My Account</h3>
                </div>
                <div>
                <Button
                    value="Back"
                    onClick={() => handleBackButton()}
                    className="button__v2 ripple--v2 button__primary ml-0 activebtn"
                  />
                </div>
              </div>
            </div>
            <div className="cardinfo__body">
              <div className="row">
                <div className="col-md-12">
                  {/* <Can I="VIEW" a="STUDENT_HOME">
                    <p className="cardinfo__pra">Please contact your teacher or school administrator if any of these details are incorrect.</p>
                  </Can> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    inputtype={"text"}
                    title={"Username"}
                    value={userName && userName}
                    username={"username"}
                    placeholder={"ashaevans"}
                    isDisabled
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    inputtype={"email"}
                    title={"Email address"}
                    email={"email"}
                    value={emailId && emailId}
                    placeholder={"ashaevans@mountainviewschool.com"}
                    isDisabled
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    inputtype={"text"}
                    title={"First name"}
                    value={firstName && firstName}
                    firstname={"firstname"}
                    placeholder={"Asha"}
                    isDisabled
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    inputtype={"text"}
                    title={"Last name"}
                    value={lastName && lastName}
                    lastname={"lastname"}
                    placeholder={"Evans"}
                    isDisabled
                  />
                </div>
                {/* <div className="col-md-6">
                                    <Input 
                                        inputtype={"text"}
                                        title={"Date of Birth"}
                                        dob={"dob"}
                                        placeholder={"04/09/2005"}
                                        isDisabled
                                    />
                                </div> */}
              </div>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <Input
                    inputtype={"text"}
                    title={"Trust Name"}
                    value={orgGroupName && orgGroupName}
                    trustname={"trustname"}
                    placeholder={"Trust name"}
                    isDisabled
                  />
                </div>
                {/* <Can I="VIEW" a="STUDENT_HOME">
                  <div className="col-md-6">
                    <Input
                      inputtype={"text"}
                      title={"Year group"}
                      value={yearGroup && decode(yearGroup)}
                      year={"year"}
                      placeholder={"Year group"}
                      isDisabled
                    />
                  </div>
                </Can> */}
                <div className="col-md-7">
                  <Input
                    inputtype={"text"}
                    title={"Password"}
                    password={"password"}
                    placeholder={"**********"}
                    isDisabled
                  />
                </div>
                <div className="col-md-5 submit--activehub">
                  <Button className="button__v2 ripple--v2 button__primary ml-0 activebtn" onClick={handlePasswordPopUp} value="Change password" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// noop funtion
const noop = () => { };

UserProfile.defaultProps = {
  handleAPIError: noop,
  popupConfig: {
    toastMessage: noop,
    setLoaderState: noop,
    setConfirmedVisible: noop,
  },
};

export default UserProfile;
