import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './index.css';
import './custem.scss'
import AppWrapper from './AppWrapper';
import "common-components-spa/dist/index.min.css";
import { routerBasePath } from './constants/Config';
import { ScrollToTop } from './utils/CustomHooks';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
        <HashRouter basename={routerBasePath}>
            <ScrollToTop />
            <AppWrapper />
        </HashRouter>,
   document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
