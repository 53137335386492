import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userId: '',
  region:'',
  userName:'',
  isValid :null,
  userDetails:{},
  selectedSpaId:'',
  defaultSpaID:'',
  iesSession:null,
  platformId:'',
  userStatus:null,
  pmrId:null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIesSession:(state, action) => {
      state.iesSession = action.payload
    },
    setLoginUserId: (state, action) => {
      state.userId = action.payload
    },
    setUserName: (state, action) => {
      state.userName = action.payload
    },
    setRegion :(state, action) => {
      state.region = action.payload
    },
    setUserDetails :(state, action) => {
      state.userDetails = action.payload
    },
    setSelectedSpaId :(state, action) => {
      state.selectedSpaId = action.payload
    },
    setdefaultSpaID:(state, action) => {
      state.defaultSpaID = action.payload
    },
    setPlatformId :(state, action) => {
      state.platformId = action.payload
    },
    setUserStatus :(state, action) => {
      state.isActive = action.payload
    },
    setCurPmrId :(state, action) => {
      state.pmrId = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoginUserId , setRegion ,setUserName,  setUserDetails,setIesSession,setSelectedSpaId,setdefaultSpaID,setPlatformId,setUserStatus,setCurPmrId} = userSlice.actions

export default userSlice.reducer