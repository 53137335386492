import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../images/logo.png';
import help from '../images/icon-help.png';
import menu from '../images/icon-menu.png';

const Footer = () => {
   const footerRedirect = (url) => {
  //   const footerLink = window.open(url);
  //   footerLink.history.pushState(
  //     null,
  //     "Pearson Global Gateway",
  //     footerLink
  //   );
    window.open(url, '_blank');
  }
   return (
<footer className='gw-footer'>
      <Container>
     
          <Nav className="footerlinks">
           
            <Nav.Link  onClick={() => footerRedirect("https://loginstatic.pearson.com/html/PearsonEULA.html?")}>Terms of use</Nav.Link>
            <Nav.Link  onClick={() => footerRedirect("https://support.pearson.com/uk/s/digital-contactus")} >Contact us</Nav.Link>
            <Nav.Link  onClick={() => footerRedirect("https://www.pearson.com/en-gb/legal-information/cookie-policy.html")} >Cookie policy</Nav.Link>
            <Nav.Link  onClick={() => footerRedirect("https://www.pearson.com/en-gb/privacy-center/privacy-notices.html")} >Privacy notice</Nav.Link>
            <Nav.Link  onClick={() => footerRedirect("https://www.pearson.com/en-gb/legal-information/accessibility.html")} >Accessibility</Nav.Link>
            <Nav.Link  onClick={() => footerRedirect("https://www.pearsonschoolsandfecolleges.co.uk/help/activehub-terms-conditions")} >Terms & conditions</Nav.Link>
            <div className='copyright'>Copyright &copy; {new Date().getFullYear()} Pearson All rights reserved.</div>
          </Nav>

        
      </Container>
    </footer>
  );
}

export default Footer;