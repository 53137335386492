
import assessments from '../images/icon-assessments.png';
import insights from '../images/icon-insights.png';

export const routerBasePath = "";
export const AH_PLATFORM_NAME = "activehub"
export const PMRs = ["urn:pearson:gps:productmodelregion:9b92df41-f614-408a-8e49-d8a255a7b52a", "urn:pearson:gps:productmodelregion:e0fe76ad-bc5a-4dc1-8c7c-caf636e7e2a3"]
export const HomeSTGLinks = [
    {
        imageUrl :assessments,
        info:'Assessment library, assessment builder and shared assessments.',
        hd:'Trust Assessments',
        spaId:"urn:pearson:gps:spa:4a3b2922-518b-4a6d-b18e-45f4b1b047dd",
        name:'Trust assessments',
    },
    {
        imageUrl :insights,
        info:'Performance analysis for trusts, schools, and assessments.',
        hd:'Trust Insights',
        spaId:"urn:pearson:gps:spa:259dd785-38b6-4120-8c68-666d70cdf038",
        name:'Trust insights'
     },
    {
        imageUrl :insights,
        info:'Performance analysis for  schools, and assessments.',
        hd:'School Insights',
        spaId:"schoolInsights",
        name:'School insights',
        alternateSpa:'urn:pearson:gps:spa:259dd785-38b6-4120-8c68-666d70cdf039'
    }
]

export const HomeProdLinks = [
    {
        imageUrl :assessments,
        info:'Assessment library, assessment builder and shared assessments.',
        hd:'Trust Assessments',
        spaId:"urn:pearson:gps:spa:d051717f-cb63-4563-969c-da8032901aeb",
        name:'Trust assessments',
    },
    {
        imageUrl :insights,
        info:'Performance analysis for trusts, schools, and assessments.',
        hd:'Trust Insights',
        spaId:"urn:pearson:gps:spa:0f63bf09-ae5f-4136-bd99-b95d469e4599",
        name:'Trust insights'
    },
    {
        imageUrl :insights,
        info:'Performance analysis for  schools, and assessments.',
        hd:'School Insights',
        spaId:"schoolInsights",
        name:'School insights',
        alternateSpa:'urn:pearson:gps:spa:0f63bf09-ae5f-4136-bd99-b95d469e4600'
    }
]
export const HomeQALinks = [
    {
        imageUrl :assessments,
        info:'Assessment library, assessment builder and shared assessments.',
        hd:'Trust Assessments',
        spaId:"urn:pearson:gps:spa:834207a7-72ba-44c0-90ed-52bc128758c7",
        name:'Trust assessments',
    },
    {
        imageUrl :insights,
        info:'Performance analysis for trusts, schools, and assessments.',
        hd:'Trust Insights',
        spaId:"urn:pearson:gps:spa:6c8148f4-ac0f-4b93-a8a7-a73733649f54",
        name:'Trust insights'
    }
]
export const spaSTGConfig =[
    {
            "spaId": "urn:pearson:gps:spa:4a3b2922-518b-4a6d-b18e-45f4b1b047dd",
            "name": "Assessments",
            "description": "assessments",
            "launchPath": "https://everest.uat.mod.pearson-intl.com/mats/dashboard",
            "styleSheetElementBaseName": "assessments",
            "audit": {
              "dateCreated": "2021-05-12T07:02:07.231Z",
              "dateModified": "2021-09-01T15:02:40.334Z",
              "createdBy": "66c09c7a525e46a1a5a6ff2ae8634a0e",
              "modifiedBy": "ffffffff5f1f98c862663401dfa66830"
            },
            "ability": {
              "action": null,
              "subject": null
            }
          },
          {
            "spaId": "urn:pearson:gps:spa:259dd785-38b6-4120-8c68-666d70cdf038",
            "name": "Trust insights",
            "description": "insights",
            "launchPath": "/mat-insights",
            "styleSheetElementBaseName": "trust insights",
            "audit": {
              "dateCreated": "2021-05-12T07:02:46.409Z",
              "dateModified": "2021-05-12T07:02:46.409Z",
              "createdBy": "66c09c7a525e46a1a5a6ff2ae8634a0e",
              "modifiedBy": "66c09c7a525e46a1a5a6ff2ae8634a0e"
            },
            "ability": {
              "action": null,
              "subject": null
            }
           },
        {
                "spaId": "schoolInsights",
                "name": "school Insights",
                "description": "school insights",
                "launchPath": "/schoolInsights",
                "styleSheetElementBaseName": "insights",
                "audit": {
                  "dateCreated": "2021-05-12T07:02:46.409Z",
                  "dateModified": "2021-05-12T07:02:46.409Z",
                  "createdBy": "66c09c7a525e46a1a5a6ff2ae8634a0e",
                  "modifiedBy": "66c09c7a525e46a1a5a6ff2ae8634a0e"
                },
                "ability": {
                  "action": null,
                  "subject": null
                }
         },
         {
                "spaId": "urn:pearson:gps:spa:259dd785-38b6-4120-8c68-666d70cdf039",
                "name": "Insights",
                "description": "insights",
                "launchPath": "/insights",
                "styleSheetElementBaseName": "insights",
                "audit": {
                  "dateCreated": "2021-05-12T07:02:46.409Z",
                  "dateModified": "2021-05-12T07:02:46.409Z",
                  "createdBy": "66c09c7a525e46a1a5a6ff2ae8634a0e",
                  "modifiedBy": "66c09c7a525e46a1a5a6ff2ae8634a0e"
                },
                "ability": {
                  "action": null,
                  "subject": null
                }
          }
]

export const spaProdConfig =[
    {
        "spaId": "urn:pearson:gps:spa:d051717f-cb63-4563-969c-da8032901aeb",
        "name": "Assessments",
        "description": "assessments",
        "launchPath": "https://everest.mod.pearson.com/mats/dashboard",
        "styleSheetElementBaseName": "assessments",
        "audit": {
            "dateCreated": "2021-05-17T13:33:05.282Z",
            "dateModified": "2021-05-19T14:23:18.021Z",
            "createdBy": "4cec593cf7fd40118377bcb7b4338e93",
            "modifiedBy": "4cec593cf7fd40118377bcb7b4338e93"
        },
        "ability": {
            "action": "VIEW",
            "subject": "ASSESSMENTS"
        }
    },
    {
        "spaId": "urn:pearson:gps:spa:0f63bf09-ae5f-4136-bd99-b95d469e4599",
        "name": "Insights",
        "description": "insights",
        "launchPath": "/mat-insights",
        "styleSheetElementBaseName": "insights",
        "audit": {
            "dateCreated": "2021-05-17T13:33:41.903Z",
            "dateModified": "2021-05-17T13:33:41.903Z",
            "createdBy": "4cec593cf7fd40118377bcb7b4338e93",
            "modifiedBy": "4cec593cf7fd40118377bcb7b4338e93"
        },
        "ability": {
            "action": "VIEW",
            "subject": "INSIGHTS"
        }
    },
    {
            "spaId": "schoolInsights",
            "name": "school Insights",
            "description": "school insights",
            "launchPath": "/schoolInsights",
            "styleSheetElementBaseName": "insights",
            "audit": {
              "dateCreated": "2021-05-12T07:02:46.409Z",
              "dateModified": "2021-05-12T07:02:46.409Z",
              "createdBy": "66c09c7a525e46a1a5a6ff2ae8634a0e",
              "modifiedBy": "66c09c7a525e46a1a5a6ff2ae8634a0e"
            },
            "ability": {
              "action": null,
              "subject": null
            }
     },
     {
            "spaId": "urn:pearson:gps:spa:0f63bf09-ae5f-4136-bd99-b95d469e4600",
            "name": "Insights",
            "description": "insights",
            "launchPath": "/insights",
            "styleSheetElementBaseName": "insights",
            "audit": {
              "dateCreated": "2021-05-12T07:02:46.409Z",
              "dateModified": "2021-05-12T07:02:46.409Z",
              "createdBy": "66c09c7a525e46a1a5a6ff2ae8634a0e",
              "modifiedBy": "66c09c7a525e46a1a5a6ff2ae8634a0e"
            },
            "ability": {
              "action": null,
              "subject": null
            }
      }
]

export const spaQAConfig =[
    {
        "spaId": "urn:pearson:gps:spa:834207a7-72ba-44c0-90ed-52bc128758c7",
        "name": "Assessments",
        "description": "assessments",
        "launchPath": "https://everest.uat.mod.pearson-intl.com/mats/dashboard",
        "styleSheetElementBaseName": "assessments",
        "audit": {
            "dateCreated": "2021-05-11T16:50:27.420Z",
            "dateModified": "2021-09-01T14:59:27.078Z",
            "createdBy": "5b41b3872dc14144a586c9ba10f66ac3",
            "modifiedBy": "379aa6e5167e42ccabe0ae390c6a679a"
        },
        "ability": {
            "action": "VIEW",
            "subject": "ASSESSMENTS"
        }
    },
    {
        "spaId": "urn:pearson:gps:spa:6c8148f4-ac0f-4b93-a8a7-a73733649f54",
        "name": "Insights",
        "description": "insights",
        "launchPath": "/mat-insights",
        "styleSheetElementBaseName": "insights",
        "audit": {
            "dateCreated": "2021-05-11T16:51:02.769Z",
            "dateModified": "2021-05-11T16:51:02.770Z",
            "createdBy": "5b41b3872dc14144a586c9ba10f66ac3",
            "modifiedBy": "5b41b3872dc14144a586c9ba10f66ac3"
        },
        "ability": {
            "action": "VIEW",
            "subject": "INSIGHTS"
        }
    }
]


export const footerLinks = [
    {
        description: "",
        footerlinkId: "1",
        launchType: "",
        launchUrl: `https://loginstatic.pearson.com/html/PearsonEULA.html?`,
        location: "",
        name: "Terms of use"
    },
    {
        description: "",
        footerlinkId: "2",
        launchType: "",
        launchUrl: `https://support.pearson.com/uk/s/digital-contactus`,
        location: "",
        name: "Contact us"
    }, {
        description: "",
        footerlinkId: "3",
        launchType: "",
        launchUrl: `https://www.pearson.com/en-gb/legal-information/cookie-policy.html`,
        location: "",
        name: "Cookie policy"
    }, {
        description: "",
        footerlinkId: "4",
        launchType: "",
        launchUrl: `https://www.pearson.com/en-gb/privacy-center/privacy-notices.html`,
        location: "",
        name: "Privacy notice"
    }, {
        description: "",
        footerlinkId: "5",
        launchType: "",
        launchUrl: `https://www.pearson.com/en-gb/legal-information/accessibility.html`,
        location: "",
        name: "Accessibility"
    }, {
        description: "",
        footerlinkId: "7",
        launchType: "",
        launchUrl: `https://www.pearsonschoolsandfecolleges.co.uk/help/activehub-terms-conditions`,
        location: "",
        name: "Terms & conditions"
    },
];