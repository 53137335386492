import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../../images/logo.png';
import help from '../../images/icon-help.png';
import close from '../../images/icon-close.png';
import signout from '../../images/signout.png'
import deleteicon from '../../images/icon-delete.png';
import {HomeSTGLinks,HomeProdLinks ,HomeQALinks} from '../../constants/Config'
import { baseHelpPageUrl } from "../../constants/HelpLink";
import {setSelectedSpaId} from "../../actions/userAction";
import { AppDrawer } from 'common-components-spa';
import { adminLinkConsts } from "../../constants/AdminLink";
import './Header.scss'
const Header = (props) => {
  const {prodHostname,stgHostname} = adminLinkConsts
  const homeLinks = window.location.hostname === prodHostname ? HomeProdLinks : window.location.hostname === stgHostname ? HomeSTGLinks :HomeSTGLinks
  const selectedSpaId = useSelector(state => state.user.selectedSpaId);
  const userDetail = useSelector(state => state.user.userDetails);
  const isActive = useSelector(state => state.user.isActive);
  const platformId = useSelector(state => state.user.platformId)
  const dispatch = useDispatch();
  let history = useHistory();
  const changeApplication = (spaId, spaName) => {
    console.log(spaId, spaName,'spaname')
    if (spaName?.toLowerCase() === "insights") {
      setSelectedSpaId("")
      setTimeout(() => {
        dispatch(setSelectedSpaId(spaId));
      }, 0)
    } else {
      dispatch(setSelectedSpaId(spaId));
    }
  }
  const appClickHandler = (path) =>{
      const appDraw = window.open(path);
      appDraw.history.pushState(
        null,
        "Pearson Global Gateway",
        appDraw
      );
  }
  const redirectHelphub = () =>{
    window.open(baseHelpPageUrl);
  }
  console.log(window.screen.width,'screen width')
    
   return (
<header>
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Container fluid>
      <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
        <Navbar.Brand href="/"></Navbar.Brand>
       
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Nav className="me-auto" defaultActiveKey="/" >
           
            <Nav.Link  onClick={() => changeApplication('dashboard', 'dashboard')}
            active={selectedSpaId === 'dashboard'
            }>Home</Nav.Link>

            {userDetail.isValid && isActive && homeLinks.map((links) =>{
              return <>
              <Nav.Link active={selectedSpaId === links.spaId || links?.alternateSpa == selectedSpaId} onClick={() => changeApplication(links.spaId, links.name)} >{links.hd}</Nav.Link>
             
              </>
            })}

             {isActive && <Nav.Link   onClick={(e) => props.adminLinkClickHandler(e)}>Trust Admin</Nav.Link>}
          </Nav>
          </Offcanvas.Body>
          </Navbar.Offcanvas> 
          <Nav className='rightnav'>
          {/* <NavDropdown className='notification'  title='' id="notification-dropdown">
            <Navbar.Text>
            Notifications <a className='close-btn'><img src={close} alt='Help' /></a>
            <a className='clearall'>Clear all</a>
          </Navbar.Text>
            
              <NavDropdown.Item className='notification-list'>Uxbridge High school entered marks for Assessment Maths

                <a className='delete'><img src={deleteicon} alt="delete" /></a>
              </NavDropdown.Item>
              <NavDropdown.Item className='notification-list'>Uxbridge High school entered marks for Assessment Maths

              <a className='delete'><img src={deleteicon} alt="delete" /></a>
              </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link onClick={() => redirectHelphub()}  className='help'><img src={help} alt='Help' /></Nav.Link>
            <NavDropdown className='username' aria-labelledby='user-dropdown'  title={props.userName ? props.userName : "GU"} id="user-dropdown">
            <Navbar.Text>
              <span className='usericon'>{props.userName ? props.userName : "GU"}</span>
              <div className='userinfo'>{userDetail.firstName} {userDetail.lastName}
            <span className='maillink' title={userDetail.email}>{(userDetail.email && userDetail.email.length > 25) ? userDetail.email.slice(0, 24) + '...' : userDetail.email}</span>
            <Nav.Link  onClick={() => changeApplication('userProfile','userProfile')} className='accountlink'>Manage Account</Nav.Link></div>
            
          </Navbar.Text>
              <NavDropdown.Item  className='logoutlink' onClick={props.handleLogout}><img src={signout} alt="signout" /> Sign out</NavDropdown.Item>
            </NavDropdown>
             <AppDrawer appDrawerList={props.appDrawerList} appClickHandler={appClickHandler} closeIcon = {close} type={platformId}/>
           </Nav>
        
        
      </Container>
    </Navbar>
    </header>
  );
}

export default Header;