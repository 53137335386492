import React ,{Suspense,useMemo,useState,useEffect,useCallback,useContext} from 'react';
import { BrowserRouter ,useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Header from './components/header/Header';
//import MicroFrontend from './MicroFrontend';
import { IESConfig, IESOptions } from "./constants/IESConfig";
import { adminLinkConsts } from "./constants/AdminLink";
import { baseHelpPageUrl } from "./constants/HelpLink";
import { 
  IESAuthUtilV2,
  useNetworkResponse,
  PageNotConfigured,
  UserNotEntitled,
  abilityBuilder,
  I18nContext,
  ProgressPopUp,
  ToastMsg,
  ErrorPopUp,
} from "common-components-spa";
import { delay, map ,catchError} from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import {
  setAuthToken ,
  setUserIdHeader,
  getUserRole,
  getHeaderInfo,
  getAppDrawerList,
  getUserLicenceStatus
} from "./network/ApiService";
import { SpaWrapper } from "./page";
import {spaSTGConfig,spaProdConfig,spaQAConfig,AH_PLATFORM_NAME} from './constants/Config'
import { Loader } from "./components/loader/Loader";
import { setLoginUserId,setUserDetails,setRegion,setIesSession,setUserName,setSelectedSpaId,setPlatformId,setUserStatus,setCurPmrId} from "./actions/userAction";
import Footer from './components/Footer';
import { CollectionsOutlined } from '@material-ui/icons';
import { handleLoginLogoutEvents } from "../src/utils/autobahn/autobahnHandler";
export const AppContext = React.createContext(null);

const getErrorComponent = (status) => {
  if (status == 404) {
    return <PageNotConfigured />;
  } else if (status == 403) {
    return <div className="user-not-entitled-wrapper">
      <UserNotEntitled />
    </div>;
  } else {
    return '';
  }
}

const USERSTATUSCONST = {
  "ACTIVE": "ACTIVE",
  "DISABLED": "DEACTIVATED"
}

const defaultErrorPopupState = {
  heading: "",
  info: "",
  btnName: "",
  btnStyle: false,
  cancelBtnName: "",
  image: "",
  subInfo: "",
  renderExtraElement: () => { },
};

const defaultProgressPopupState = {
  done: false,
  waitingMsg: "",
  itemName: "",
  type: "",
  loadingImage: "",
  animationDuration: 4,
  onSuccess: () => { },
  status: null,
};


const defaultConfirmPopupState = {
  isModalshow: false,
  title: "",
  dialogMsg: "",
  positiveButtonText: "",
  positiveButtonHandle: () => { },
  negativeButtonText: "",
  negativeButtonHandle: () => { },
  positiveButtonStyleClass: "",
  negativeButtonStyleClass: "",
  subInfo: "",
};
const defaultToastObj = {
  title: "",
  msg: "",
  styleClass: "",
  delay: 0,
  autoHide: false,
  toastImg: "",
  show: false,
};
const emptyAbilities = abilityBuilder([]);
const App = props => {
  const regionId =useSelector(state => state.user.region);
  const userId = useSelector(state => state.user.userId);
  const [isTokenExist, setIsTokenExist] = useState(false);
  const [isValid,setIsValid] =  useState(null);
  const [isActive, setIsActive] = useState(null);
  const iesSession = useSelector(state => state.user.iesSession); 
  const userDetails = useSelector(state => state.user.userDetails); 
  const selectedSpaId = useSelector(state => state.user.selectedSpaId);
  const [pmrId , setPmrId] = useState(null)
  const userName = useSelector(state => state.user.userName);
  const [availableSPAs, setAvailableSPAs] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [isApiloading, setApiLoading] = useState(false);
  const [errorCode,setError] = useState(null)
  const dispatch = useDispatch();
  const [appDrawerList, setAppDrawerList] = useState([])
  const setToken = useCallback((tokenValue) => {
    setAuthToken(tokenValue);
    setIsTokenExist(true);   
  }, []);
  const [progressPopupState, setProgressPopupState] = useState(
    defaultProgressPopupState
  );
  const [confirmPopupState, setConfirmPopupState] = useState(
    defaultConfirmPopupState
  );
  const [errorPopupState, setErrorPopupState] = useState(
    defaultErrorPopupState
  );
  const [errorPopupVisibility, setErrorPopupVisibility] = useState(false);
  const [toast, setToast] = useState(defaultToastObj);
  const {
    translate: userTranslate,
    translateHTML,
  } = useContext(I18nContext);
  const [translate, setTranslate] = useState(() => userTranslate);
  const headerInfoResponse = useNetworkResponse();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const redirect = query.get('switch');

  let popups = useMemo(() => {
    return {
      errorPopup: {
        setState: (state) => {
          setErrorPopupState({
            ...defaultErrorPopupState,
            onClosePopup: () => setErrorPopupVisibility(false),
            buttonAction: () => setErrorPopupVisibility(false),
            cancelButtonAction: () => setErrorPopupVisibility(false),
            ...state,
          });
        },
        setVisibility: setErrorPopupVisibility,
      },
    };
  }, []);
  function closeToast() {
    setToast(defaultToastObj);
  }

  useEffect(() => {
    const {prodHostname,stgHostname,qaPlatformId,stgPlatformId,prodPlatformId } = adminLinkConsts
    console.log(qaPlatformId,stgPlatformId,prodPlatformId)
    if (window.location.hostname === prodHostname) {    
      dispatch(setPlatformId(prodPlatformId))
    } else if (window.location.hostname === stgHostname) {    
      dispatch(setPlatformId(stgPlatformId))
    } else {    
      dispatch(setPlatformId(qaPlatformId))
    }
    }, [adminLinkConsts])
 
  useEffect(() => {
    if (!iesSession) return
    const redirectToPreLogin = () => {
      localStorage.clear();
      if(window.location.origin == "https://activehub-trust.pearson.com"){
        window.location.assign(`https://activehub.pearson.com${window.location.pathname}#/login`);
      }else{
        window.location.assign(`${window.location.origin}/#/login`);
      }
     
    }
    iesSession.onLogout(redirectToPreLogin);
  }, [iesSession])
  
  useEffect(() => {   
    (async function() {
      setUserIdHeader(userId);
    })();     
  }, [userId]);


  useEffect(() => {   
    (async function() {
      fetchAppDrawerList(userId);
    })();     
  }, [userId]);

      const fetchAppDrawerList = (userId) => {
      const list = []
      let appDrawList =  getAppDrawerList(userId)      
      .pipe(catchError(err => of(err))); 
      let subscription = forkJoin(appDrawList).subscribe(res => {
        console.log(res,'res')
        res[0] && res[0][0] && res[0][0].map((data)=>{
               list.push({...data})
               if(redirect){
                localStorage.setItem('isRedirectedToAH', true);
               }
               if (!localStorage.getItem('isRedirectedToAH') && !redirect) {  
                if(data?.name?.toLowerCase() === AH_PLATFORM_NAME.toLowerCase()){                  
                  const redirectURL = (new URL(data?.launchUrl));
                  localStorage.setItem('isRedirectedToAH', true);
                   window.location.href = `https://${redirectURL.hostname}/#/application/dashboard?switch=true`
                 }
               }
                                
        })
        setAppDrawerList(list)
      },
      error => {
        console.log("error: ", error);
      });
      return () => subscription.unsubscribe();
      };


  useEffect(() => {
    const loginTypeVal = sessionStorage.getItem("LOGINTYPE");
    const loginParams = (loginTypeVal) ? { queryParams: { loginType: loginTypeVal } } : {};
    const iesSubscription = IESAuthUtilV2.load({
      ...IESConfig,
      callbackURL: window.location.href,
      domain: window.location.hostname,
      ...loginParams
    }, IESOptions).pipe(map(iesdata => { window.IES = iesdata; console.log("Login Map", iesdata.getUserId()); return (iesdata) }), delay(3000)).subscribe((session) => {

      dispatch(setIesSession(session));

      if (!window.IES.getUserId() && isBaseUrl()) {
        console.log("Login Failed", window.IES.getUserId())
        window.location.assign(`${window.location.origin}${window.location.pathname}#/login`);
      } else {
        localStorage.setItem('preLoginUserId', window.IES.getUserId());
        //localStorage.setItem('isRedirectedToMat', true);
        console.log("Login Success", window.IES)
        session.login();
        dispatch(setLoginUserId(session.getUserId()));
        //handleLoginLogoutEvents("login");
      }


      dispatch(setLoginUserId(session.getUserId()));
      dispatch(setSelectedSpaId('dashboard'));
      setToken(session.getPiSessionObj().currentToken());
      session.onRefresh((refreshEvent) => {
        // setToken(refreshEvent.data);
        refreshEvent.data && dispatch(setLoginUserId(session.getUserId()));
        refreshEvent.data && setToken(refreshEvent.data);

        if (!refreshEvent.data) {
          console.log("Login Failed")
          window.location.assign(`${window.location.origin}${window.location.pathname}#/login`);
        }

      });
    });

    return () => iesSubscription.unsubscribe();
    // no need to call ies again if setters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBaseUrl = () => {
    // https://qa.pet.ift.pearson-intl.com/activehub/index.html?iesError=login_required&iesErrorDescription=Login+required#/
    // https://stg.pet.ift.pearson-intl.com/activehub/index.html?iesError=login_required&iesErrorDescription=Login+required#/
    console.log('window.location.href', window.location.href);
    let environment = ['dev', 'qa', 'stg', 'perf']
    let gethostname = window.location.hostname.split(".")[0];
    let domainBasedUrl = environment.includes(gethostname) ? `https://${gethostname}.pet.ift.pearson-intl.com/mat/index.html?iesError=login_required&iesErrorDescription=Login+required#/` : 'https://activehub-trust.pearson.com/#/';
    return (window.location.href === domainBasedUrl || window.location.href === "http://gateway.pearson.com:3009/#/");
  }

  // useEffect(() => {
  //   console.log("Process Env", process.env)
  //   if (!iesSession) return
  //   const redirectToPreLogin = () => {
  //     window.PRELOGIN_URL = '';
  //     localStorage.clear();
  //     window.location.assign(`${window.location.origin}${window.location.pathname}#/login`);
  //   }
  //   iesSession.onLogout(redirectToPreLogin);
  // }, [iesSession])  


  // useEffect(() => {
  //   if (!window.location.href.includes('iesError=login_required&iesErrorDescription=Login+required#/login')) {
  //     window.PRELOGIN_URL = window.location.href;
  //   }
  //   const loginTypeVal = sessionStorage.getItem("LOGINTYPE");
  //   const loginParams = (loginTypeVal) ? { queryParams: { loginType: loginTypeVal } } : {};
  //   const iesSubscription = IESAuthUtilV2.load({
  //             ...IESConfig,
  //             callbackURL: `${window?.piSession?.currentToken() && window.location.href.includes('/login') ? window.location.href.split("#")[0] : window.location.href}`,
  //             domain: window.location.hostname,
  //             ...loginParams
  //           }, IESOptions).subscribe((session) => {
  //             session.login();
  //             dispatch(setIesSession(session));
  //     if (session.hasValidSession()) {
  //       dispatch(setLoginUserId(session.getUserId()));
  //       dispatch(setSelectedSpaId('dashboard'));
  //     }
  //     session.onRefresh((refreshEvent) => {
  //       // setToken(refreshEvent.data); 
  //      refreshEvent.data && dispatch(setLoginUserId(session.getUserId()));
  //      refreshEvent.data && setToken(refreshEvent.data);

  //     });
  //   });

  //  return () => iesSubscription.unsubscribe();
  //   // no need to call ies again if setters change
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  //const PMRID  = "urn:pearson:gps:productmodelregion:e0fe76ad-bc5a-4dc1-8c7c-caf636e7e2a3";
  const PMRIDs = ["urn:pearson:gps:productmodelregion:e0fe76ad-bc5a-4dc1-8c7c-caf636e7e2a3",
    "urn:pearson:gps:productmodelregion:9b92df41-f614-408a-8e49-d8a255a7b52a"];

  useEffect(() => {   
    console.log(pmrId,'pmrId')
    if (isTokenExist && pmrId != null){
      fetchUserStatus(pmrId)
    }          
  }, [isTokenExist,pmrId]);

  useEffect(() => {
    if (!userId || !isTokenExist) return;   
    headerInfoResponse.dispatch({
      type: "STARTED",
    });
    const subscription = getHeaderInfo().subscribe(
      (response) => {
        headerInfoResponse.dispatch({
          type: "SUCCESS",
          payload: response,
        });
        sessionStorage.setItem("regionDetail", JSON.stringify(response));
        dispatch(setRegion(response.regionId));
      },
      (error) => {
        headerInfoResponse.dispatch({
          type: "ERROR",
          payload: error,
        });
        if (error && error.data && error.data.status >= 500) {
        
        } else if (error && error.data && error.data.status === 401) {
          iesSession.logout();
        } else if (error && error.data && error.data.status === 404) {
          console.log("error 404 for region");
         // setWrapperLevelErrorPage(getErrorComponent(404));
         setError(404)
        } else if (error && error.status === 403) {
          console.log("error 403 for region");
          setError(403)
          // if (isRedeemCodePage) return;
          //setWrapperLevelErrorPage(getErrorComponent(403));
        }
        console.log("Error in API", error);
      }
    );

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isTokenExist]);

  useEffect(() => {
    if (!regionId) return;
    if(isValid == null){
      setLoading(true);
      const subscription = getUserRole({
        filter: {
          userId,
          regionId,
        },
      }).subscribe(
        (response) => {   
          if (response && response[0] ) {               
            
                 if( response[0].organizationGroups !=null && response[0].organizationGroups.length > 0 && PMRIDs.indexOf(response[0].organizationGroups[0].productModelRegionId) != -1){
                  dispatch(setCurPmrId(response[0].organizationGroups[0].productModelRegionId))
                  setPmrId(response[0].organizationGroups[0].productModelRegionId)
                      setIsValid(true);
                      localStorage.setItem('trustId', response[0].organizationGroups[0].orgGroupId);
                        }else{
                          setIsValid(false);
                          }  
                }else{
                  setIsValid(false);
                }

                let firstInitial = response[0].firstName && response[0].firstName.length > 0 &&
                response[0].firstName[0].toUpperCase();
              let familyInitial = response[0].lastName && response[0].lastName.length > 0 &&
              response[0].lastName[0].toUpperCase();
              dispatch(setUserName(
                `${firstInitial || ''}${familyInitial || ''}`,
              ))
                localStorage.setItem('trustName',response[0]?.organizationGroups[0]?.orgGroupName)
                dispatch(setUserDetails({
                  userId:response[0].userId,
                  firstName:response[0].firstName,
                  lastName:response[0].lastName,
                  email:response[0].email,
                  userName:response[0].userName,
                  role:response[0].roles !=null && response[0]?.roles[0] ? response[0].roles[0].role :'',
                  organizationGroupId:response[0].organizationGroups !=null && response[0]?.organizationGroups[0] ? response[0].organizationGroups[0].orgGroupId :'',
                  orgGroupName:response[0].organizationGroups !=null && response[0]?.organizationGroups[0] ? response[0].organizationGroups[0].orgGroupName :'',
                  isValid:response[0].organizationGroups !=null && PMRIDs.indexOf(response[0]?.organizationGroups[0]?.productModelRegionId) != -1 ? true :false
                }));
                
          setLoading(false);
            },
        (error) => {
          if (error && error.data && error.data.status === 404) {           
           setError(404)
          } else if (error && error.status === 403) {
            setError(403)
            
          }
          setLoading(false);
        }
      );
      return () => subscription.unsubscribe();
      // dont need to run this effect when setters change
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    
  },[userId, isTokenExist,regionId]);

 
  const fetchUserStatus = (pmrId) => {
    setApiLoading(true)
    const subs = getUserLicenceStatus(pmrId).subscribe(res => {     
      console.log(res)     
         setIsActive(res[0]?.validLicense)   
         dispatch(setUserStatus(res[0]?.validLicense))  
         setApiLoading(false)
    },
    err => {
      setIsActive(false) 
      setApiLoading(false) 
      dispatch(setUserStatus(false))     
      console.log("Error in status fetch", err)
    })
  }

  useEffect(() => {
    console.log("User Data window USERPROFILE >>>", window.USERPROFILE)
    console.log("User Data Organization >>>", userDetails)    
    const gtmLoad = window.dataLayer?.length && window.dataLayer?.some(data => data?.event === 'gtm.load');
    console.log(userDetails,'userDetails',userDetails.length,gtmLoad)
    if (Object.keys(userDetails).length != 0  && gtmLoad) {
     
      window.dataLayer = window.dataLayer || [];
      //let userData = window.USERPROFILE
      let googleLoggedInData = {
        person_id: userDetails.userId, // User Id
        person_id_type: "IES", // User Authentication Type
        person_role_code: userDetails.role, // User’s Role
        login_session_id: window.piSession?.getContextId(), // Login Session Id 
        organization_group_id: userDetails.organizationGroupId, // organizationGroupId / trust Id
        organization_group_id_type: userDetails.orgGroupName // organizationGroup Type
      }
      window.dataLayer.push(googleLoggedInData)

    }
  }, [userId, userDetails,window.dataLayer]);
  

  useEffect(() => {
    if (!regionId) return;
    let spaConfig = []
    let { prodHostname, stgHostname } = adminLinkConsts

    if (window.location.hostname === prodHostname) {
      spaConfig = spaProdConfig
    } else if (window.location.hostname === stgHostname) {
      spaConfig = spaSTGConfig
    } else {
      spaConfig = spaQAConfig
    }

    setAvailableSPAs(spaConfig);
    const assessmentsSpaId = spaConfig.filter(data => 
      data.description === 'assessments'
    )
    const insightsSpaId = spaConfig.filter(data => 
      data.description === 'insights'
    )  
    console.log(assessmentsSpaId,insightsSpaId)
    sessionStorage.setItem("assessmentsSpaId", encodeURIComponent(assessmentsSpaId[0].spaId) || null);
    sessionStorage.setItem("insightsSpaId", encodeURIComponent(insightsSpaId[0].spaId) || null);

  }, [regionId,spaQAConfig,spaSTGConfig,spaProdConfig])

  function handleLogout(event) {
    event.stopPropagation();
    event.preventDefault();   
    handleLoginLogoutEvents("logout");
    window.dataLayer = [];
    iesSession.logout();
  }


  const appContextValue = useMemo(() => {
    return {     
      translate,
      translateHTML,
      setTranslate,       
      setProgressPopupState,     
      setConfirmPopupState,
      setToast,     
      ...popups,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popups ]);
  
  if (!window.piSession || !userId || isloading || isApiloading) {
    return <Loader />
  }else if (!isTokenExist || !userId || headerInfoResponse.isLoading) {
    return <Loader />;
  }

  const adminLinkClickHandler = (e) => {
    e?.stopPropagation()
    let { prodHostname, stgGatewayHostname, 
      stgGatewaySpaId, prodGatewayHostname, prodGatewaySpaId, 
      qaGatewaySpaId, qaGatewayHostname, stgHostname } = adminLinkConsts
    let gatewayhostname, gatewaySpaId;
    if (window.location.hostname === prodHostname) {
      gatewayhostname = prodGatewayHostname
      gatewaySpaId = prodGatewaySpaId
    } else if (window.location.hostname === stgHostname) {
      gatewayhostname = stgGatewayHostname
      gatewaySpaId = stgGatewaySpaId
    } else {
      gatewayhostname = qaGatewayHostname
      gatewaySpaId = qaGatewaySpaId
    }
    const adminUrl = `https://${gatewayhostname}/#/application/${gatewaySpaId}/user-list`
    const adminTab = window.open(adminUrl);
    adminTab.history.pushState(null, 'Pearson Global Gateway-Admin-User accounts', adminUrl);
  }  

  return (    
    <AppContext.Provider value={appContextValue}>
       <ToastMsg
            title={toast.title}
            show={toast.show}
            msg={toast.msg}
            status={toast.status}
            onClose={() => closeToast()}
            styleClass={toast.styleClass}
            delay={toast.delay}
            autoHide={toast.autoHide}
            toastImg={toast.toastImg}
          />
          <ProgressPopUp
            isOpen={progressPopupState.isOpen}
            waitingMsg={progressPopupState.waitingMsg}
            done={progressPopupState.done}
            itemName={progressPopupState.itemName}
            type={progressPopupState.type}
            successMsg={progressPopupState.successMsg}
            animationDuration={progressPopupState.animationDuration}
            onSuccess={progressPopupState.onSuccess}
            status={progressPopupState.status}
            loadingImage={progressPopupState.loadingImage}
            headerContent={progressPopupState.headerContent}
            footerCustomElement={progressPopupState.footerCustomElement}
          />
           <ErrorPopUp
            isOpen={errorPopupVisibility}
            heading={errorPopupState.heading}
            info={errorPopupState.info}
            btnName={errorPopupState.btnName}
            buttonAction={errorPopupState.buttonAction}
            btnStyle={errorPopupState.btnStyle}
            cancelBtnName={errorPopupState.cancelBtnName}
            cancelButtonAction={errorPopupState.cancelButtonAction}
            cancelBtnStyle={errorPopupState.cancelBtnStyle}
            image={errorPopupState.image}
            closePopup={errorPopupState.onClosePopup}
            subInfo={errorPopupState.subInfo}
            infoClassName={errorPopupState.infoClassName}
            subInfoClassName={errorPopupState.subInfoClassName}
            renderExtraElement={errorPopupState.renderExtraElement}
          />
     <Suspense fallback={<div>Loading...</div>}>
     <Header userName = {userName} 
     userDetails = {userDetails}
     handleLogout={handleLogout} 
     adminLinkClickHandler={adminLinkClickHandler}
     baseHelpPageUrl={baseHelpPageUrl}
     appDrawerList={appDrawerList}
     />
      <SpaWrapper errorCode={errorCode}/>  
      <Footer />
      </Suspense>    
      </AppContext.Provider>  
    
  );
};

export default App;
