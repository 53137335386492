  
import React,{useMemo,useEffect, useState} from 'react';
import { useDispatch,useSelector } from "react-redux";
import {  useRouteMatch} from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import {NavLink} from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {HomeSTGLinks,HomeProdLinks,HomeQALinks} from '../../constants/Config'
import { Loader } from "../../components/loader/Loader";

import {setSelectedSpaId} from "../../actions/userAction";
import {handleLoginLogoutEvents ,handlePageviewEvent} from '../../utils/autobahn/autobahnHandler'
import { getSchoolsPermissionList} from "../../network/ApiService";
import {spaSTGConfig,spaProdConfig,spaQAConfig} from '../../constants/Config'
import { adminLinkConsts } from "../../constants/AdminLink";

const SchoolList = () => {
 
  const userDetail = useSelector(state => state.user.userDetails);
  const pmrId = useSelector(state => state.user.pmrId)
  const [grantedList ,setGrantedList] = useState([])
  const [notGrantedList ,setNotGrantedList] = useState([])
  const [isLoad,setIsLoad] = useState(true);
    const selectedSpaId = useSelector(state => state.user.selectedSpaId);
    const dispatch = useDispatch();
    const {prodHostname,stgHostname} = adminLinkConsts
    const spaConfig = window.location.hostname === prodHostname ? spaProdConfig : window.location.hostname === stgHostname ? spaSTGConfig :spaSTGConfig
    const spa = spaConfig.find(spa => spa.launchPath === '/insights');
    

    useEffect(() => {
      fetchSchoolList()
    },[])

    const fetchSchoolList = () => {
      const subs = getSchoolsPermissionList(pmrId,userDetail.organizationGroupId).subscribe(res => {
        if(typeof res[0] != 'undefined' && res[0].length > 0){
          const schoolList = convertSchoolStatus(res[0]) 
          setGrantedList(schoolList.grantedList) 
          setNotGrantedList(schoolList.notGrantedList)
        }          
        setIsLoad(false)       
      },
      err => {    
        setIsLoad(false)        
        console.log("Error in status fetch", err)
      })
    }
    const convertSchoolStatus = (list) => {

      const sortedList = list.sort((a, b) => {
        return a['schoolName'].localeCompare(b['schoolName'])
      })
      console.log(sortedList,'sortedList')

      const grantedList = [],notGrantedList = []
      sortedList.map((schools) => {
            if(schools.permission == "GRANTED"){
              grantedList.push(schools)
            }else{
              notGrantedList.push(schools)
            }
        })

        //grantedList.sort((a, b) => a['schoolName'] - b['schoolName'])
        //notGrantedList.sort((a, b) => a['schoolName'] - b['schoolName'])

        return {notGrantedList,grantedList}
    }
 
   /**
   * Create nav data from 
   * - admin and help link statically
   * - Other from spa configuration API
   */
   const RedirectToInsight = (schoolId) =>{
    
    localStorage.setItem('defaultSpaId', spa.spaId); 
    localStorage.setItem("MATSchoolID", schoolId);
    const envUrlParam = window.location.pathname == "/mat/index.html" ? 'mat/index.html' : ''
    let InsightsUrl = `${window.location.origin}/${envUrlParam}#/application/${spa.spaId}/dashboard/${schoolId}`;
    window.history.pushState(null, `Insights | Dashboard `, InsightsUrl);
   window.location.reload();
  }


  return (
    <div className='schoolmainwrapper'>
      {isLoad && <Loader/>}
      <div className='sidebar'>
      <Navbar key='lg' bg="light" expand='lg'>
        {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} /> */}
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav defaultActiveKey="/" className="flex-column">
            <NavLink className="all-school-lhs" exact to='#'>All schools</NavLink>            
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </div>
                        

        <div className={`container`} style={{paddingTop:'20px'}}>      
              <Row>
                <h1 className='col-md-4 school-insights' role='heading' aria-level='1'>
                  School Insights
                </h1>          
              </Row>
              <Row>
                <div className='col-md-auto school-desc'>
                The following are all the schools in your trust. For schools that have granted access, select the schools to view results at school, group and student level, in a read-only format. For schools that have not granted access, the school admin user can do so in their Admin area of ActiveHub.
                </div>
              </Row>
              <Row>
                <div className='col-md-auto school-status'>
                    Schools that have granted access
                </div>
              </Row>
              { grantedList.length > 0 &&  <Row>         
                  {
                    grantedList.map(results=>{        
                    
                      return <div style={{marginBottom:"10px"}}>
                                <Col xs={4} style={{"height":"100%"}}>
                                      <Card style={{"width":"20.5rem","height":"100%"}} className='granted' onClick={results.permission == 'GRANTED'? () => RedirectToInsight(results.schoolId) :(e) => e.preventDefault()} >
                                        <Card.Header  style={{"backgroundColor":"#D2DB0E"}}></Card.Header>
                                        <Card.Body>
                                          <Card.Title className='school-title'>{results.schoolName}</Card.Title>
                                        </Card.Body>
                                      </Card>
                                </Col>                 
                          </div>
                              


                          })
                            }
                </Row>}
                {grantedList.length == 0 && !isLoad &&  <row className="no-schools">No schools</row>}

                <Row>
                <div className='col-md-auto school-status'>
                   Schools that have not granted access
                </div>
              </Row>
          { notGrantedList.length > 0 && <Row>         
                  {
                     notGrantedList.map(results=>{        
                    
                      return <div style={{marginBottom:"24px"}}>
                                <Col xs={4} style={{"height":"100%"}}>
                                      <Card style={{"width":"20.5rem","height":"100%"}} className="not-granted" onClick={results.permission == 'GRANTED'? () => RedirectToInsight(results.schoolId) :(e) => e.preventDefault()} >
                                        <Card.Header  style={{"backgroundColor":"#BDC5D2"}}></Card.Header>
                                        <Card.Body>
                                          <Card.Title className='school-title'>{results.schoolName}</Card.Title>
                                        </Card.Body>
                                      </Card>
                                </Col>                 
                          </div>
                              


                          })
                            }
                </Row>}
                {notGrantedList.length == 0 && !isLoad &&  <row className="no-schools">No schools</row>}
          </div>

    </div>
    
  )
}

export default SchoolList;