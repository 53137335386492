import { NetworkOps } from 'common-components-spa'

export const userLocale = navigator.language || 'en-US';
let environment = ['dev', 'qa', 'stg', 'perf']
let gethostname = window.location.hostname.split(".")[0];
let nullProxyBaseUrl = environment.includes(gethostname) ? `https://${gethostname}.ggw.ift.pearson-intl.com` : 'https://ggw.ift.pearson-intl.com';
let baseURL = (sessionStorage.getItem('proxyBaseUrl') != null) ? sessionStorage.getItem('proxyBaseUrl') : nullProxyBaseUrl;
const permissionAPI = new NetworkOps({
    baseURL: `/api/mat-node/permission/v1`
})

const userAbilityAPI = new NetworkOps({
    baseURL: `/api/mat-node`
})

export const rootAPI = new NetworkOps({
    baseURL: 'https://stg.ggw.ift.pearson-intl.com/api'
});

export const proxyAPI = new NetworkOps({
    baseURL: `/api/mat-node/proxy/v1`
});

export const gatewayConfigurationApi = new NetworkOps({
    baseURL: `/api/mat-node/configuration/v1`,
});

export const notificationApi = new NetworkOps({
    baseURL: `/api/mat-node/notification/v1`,
});

/* whenever a new networkOps is defined add it in setAuthToken and setUserIdHeader */
export function setAuthToken(token) {
    console.log(token,'token')
    rootAPI.setAuthToken(token);
    permissionAPI.setAuthToken(token);
    userAbilityAPI.setAuthToken(token);
    proxyAPI.setAuthToken(token);
    gatewayConfigurationApi.setAuthToken(token);
    notificationApi.setAuthToken(token);
}

export function setUserIdHeader(id) {
    rootAPI.setUserId(id);
    permissionAPI.setUserId(id);
    userAbilityAPI.setUserId(id);
    proxyAPI.setUserId(id);
    gatewayConfigurationApi.setUserId(id);
    notificationApi.setUserId(id);
}

export function getUserProfile(userId) {
    return userAbilityAPI.get(`/user/v1/user/${userId}`);
}
/**
 * Update the user profile
 * @param userId 
 * @param data 
 */
export function updateUserProfile(userId, data) {
    return userAbilityAPI
        .patch(`/user/v1/user/${userId}/profile`, data);
}

/**
 * Getting the Role of the user
 * @param data 
 * @param token 
 */
export function getUserRole(data) {
    return userAbilityAPI
        .post('/usersearch/v1/search/users', data);
}

export function getUserAbilities(userId, regionId) {
    return proxyAPI.get(`/getUserAbilityByUserIdRegionId/${userId}/${regionId}`)
}
export function getSPAsInRegion(region, locale = userLocale) {
    return proxyAPI.get(`/getSPAInRegion/${region}/locale/${locale}`)
}

export function getHeaderInfo() {
    return gatewayConfigurationApi.get(
        `/configuration/findByHostname/${window.location.host}/locale/${userLocale}`);
}

export function getNotifications() {
    return notificationApi.get(`/inappnotifications/unread`);
}

export function readNotification(noId) {
    return notificationApi.put(`/inappnotification/${noId}/read`);
}

export function getUserStatus(pmrIds) {
    return proxyAPI.post(`/getUserStatus`, pmrIds);
}

export function getUserLicenceStatus(pmrId) {
    return proxyAPI.get(`/getUserCurrentStatus/pmr/${pmrId}`);
}

export function getSchoolsPermissionList (pmrId,trustId) {
    return proxyAPI.get(`/getSchoolsPermissionList/pmr/${pmrId}/trustId/${trustId}`);
}

export function getOrgDetailsByUser(userId) {
    return proxyAPI.post(`/organisationDetailsForUser/user/${userId}/locale/en-GB`)
  }

export function getAppcuesConfig() {
    return rootAPI.get(`/getAppcuesConfig`)
}

export function getTierInformation() {
    return proxyAPI.get(`/getTierData`)
  }
export function getAppDrawerList(userId) {
    return proxyAPI.get(`/getAppDrawerData/user/${userId}/locale/en-US`)
  }