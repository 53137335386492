import React from 'react';
import PreLogin from './components/pre-login/PreLogin';
import App from './App';
import { Route, Switch } from "react-router-dom";
import { store } from './store'
import { Provider} from "react-redux";
console.log('im in')
export default function AppWrapper() {
    return (
        <Provider store={store}>
            <Switch>
                <Route exact path="/login">
                    <PreLogin />
                </Route>
                <Route path="/">
                    <App />
                </Route>
            </Switch>
            </Provider>
       
    )

}