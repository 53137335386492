import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Button, IESAuthUtilV2 } from 'common-components-spa';
import { IESConfig, IESOptions } from '../../constants/IESConfig';
import './PreLogin.scss';
import { Footer } from '../footer/Footer';
import PreLoginMobile from './PreLoginMobile';
import { footerLinks } from '../../constants/Config';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function PreLogin(props) {

    const [iesSession, setIesSession] = useState(null);
    const isMobile = !useMediaQuery('(min-width:768px)');
    const isTablet = useMediaQuery('(min-width:768px) and (max-width: 1024px)');

    const setClient = (e) => {
        sessionStorage.setItem("LOGINTYPE", e.target.id)
        iesSession.login();
    }

    useEffect(() => {
        const userIdKey = Object.keys(localStorage).find(key => key.includes('PiUserId'))
        const successUrl = window.location.href.includes('/login') ? window.location.href.split("#")[0] : window.location.href;
        if (localStorage.getItem(userIdKey)) {
            window.location.assign(successUrl);
        }
        console.log('successUrl', successUrl)
        const loginTypeVal = sessionStorage.getItem("LOGINTYPE");
        const loginParams = (loginTypeVal) ? { queryParams: { loginType: loginTypeVal } } : {};
        const iesSubscription = IESAuthUtilV2.load({
            ...IESConfig,
            callbackURL: successUrl,
            domain: window.location.hostname,
            ...loginParams
        }, IESOptions).subscribe((session) => {
            console.count("IES Session")
            setIesSession(session);
        });

        return () => iesSubscription.unsubscribe();
    }, []);

    return (
        <>
            <Helmet>
                <title>ActiveHub</title>
            </Helmet>
            {(isMobile || isTablet) ? <PreLoginMobile footerLinks={footerLinks} setClient={setClient} isMobile={isMobile} /> :
                <div className="row m-0 creation prelogin-desktop">
                    <div className="row m-0 creation__wrapleft w-100">
                        <div className="col-12 col-md-12 col-lg-9 p-0 pr-sm-5">
                            <div className="activehub-text">
                                <div className="creation__logo">
                                    ActiveHub
                                </div>
                                <div className="creation__insights">
                                    Powered by Insights.
                                </div>
                                <div className="creation__insights creation__insights__guided">
                                    Guided by you.
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-3 p-0 activehub-logo-container">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/activehub-logo-desktop.svg`}
                                alt="logo"
                                className="activehub-logo desktop"
                            />
                            <img
                                src={`${process.env.PUBLIC_URL}/images/activehub-logo-laptop.svg`}
                                alt="logo"
                                className="activehub-logo laptop"
                            />
                        </div>
                    </div>
                    <div className='row creation__wrapleft w-100 btn-wrapper'>
                        <div className='col-12 col-md-12 col-lg-6'>
                            <div className='row m-0 w-100'>
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <Button
                                        useButtonClass={false}
                                        ariaDescribedby={"sign in"}
                                        onClick={setClient}
                                        id="ALSecondary"
                                        className={`sign-in`} >
                                        <span className='sign-in-text'>Sign in</span>
                                    </Button>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12 p-0 mt-link">
                                    <span className='links mr-4'><a rel='noreferrer' target="_blank" href='https://www.pearsonschoolsandfecolleges.co.uk/activehub'>Find out more</a></span>
                                    <span className='links'><a rel='noreferrer' href='https://support.pearson.com/uk/s/digital-contactus' target="_blank">Contact us</a></span>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-12 col-md-12 col-lg-6 pl-md-0 pl-sm-0'>
                            <div className='updates mb-3'>
                                Latest Updates
                            </div>
                            <div className="gsamblk m-0">
                                <div className="gsamblk__txt-wrp">
                                    <div className='update-heading'>
                                        New Science online activities
                                    </div>
                                    <div className='gsamblk__txt-wrp_txt'>
                                        You can now assign our new combined science activities with more interactive questions and examples that will help your students understand blah blah blah blah blah blah blah.
                                    </div>
                                    <div className='learn-more'>
                                        <span className='mr-2'>New Science online activities</span>
                                        <span>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/learn-more.svg`}
                                                alt="logo"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div role="contentinfo" className="gwWraper_footer">
                        <Footer
                            footerLinks={footerLinks || []}
                        />
                    </div>
                </div>}
        </>
    )
}