import React, { useContext, useState } from "react";
import {PasswordInput,ConfirmPopUp} from 'common-components-spa'
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from '../../App';
import UserProfile from "../userProfile/UserProfile";
import { updateUserProfile } from "../../network/ApiService";
import './UserWrapper.scss'

const initUser = {
  confirmNewPassword: "",
  oldPassword: "",
  newPassword: "",
  validation: {
    confirmNewPassword: "",
    oldPassword: "",
    newPassword: "",
  },
};

const UserWrapper = () => {
  const selectedSpaId = useSelector(state => state.user.selectedSpaId);
  // getting values from context
  const {
    translateHTML: tHTML,
    setToast,
    translate: t,
    setProgressPopupState,
    errorPopup    
  } = useContext(AppContext);
  
  const initialprogressConfig = {
    headerContent: t("Please wait while we updating your details"),
    status: { msg: t("Updating User") },
    progress: true,
    animationDuration: 0.5,
    done: false,
  };
 
  const [inputsObject, setInputsObject] = useState(initUser);
  const [isModalshow,setIsModalshow]=useState(false)
  const setLoaderState = (isVisible) => {
    // setting the progress popup state
    setProgressPopupState({
      ...initialprogressConfig,
      isOpen: !!isVisible,
    });
    setIsModalshow(false)
  };
  
  function handleInput(e) {
    const { name, value } = e.target;
    setInputsObject({
      ...inputsObject,
      [name]: value,
      validation: { ...inputsObject.validation, [name]: "" },
    });
  }
  const toastMessage = ({ msg, success }) => {
    setToast({
      title: null,
      msg: tHTML(msg, {
        unsafe: true,
      }),
      styleClass: "",
      delay: 3000,
      autoHide: true,
      toastImg: `${process.env.PUBLIC_URL}/images/${success ? "success.svg" : "generic-error-icon.svg"
        }`,
      show: true,
    });
  };
  function updateClickHandler() {
    const { confirmNewPassword, oldPassword, newPassword } = inputsObject;
    if (confirmNewPassword && oldPassword && newPassword) {
      if (confirmNewPassword !== newPassword) {
        setInputsObject({
          ...inputsObject,
          validation: {
            ...inputsObject.validation,
            confirmNewPassword:
              "New Password and Confirm New Password don't match.",
          },
        });
        return false;
      }
      handleUpdatePassword();
      //handleUpdateDetailsSuccess();
    }
    let validationObj = {
      confirmNewPassword: "",
      oldPassword: "",
      newPassword: "",
    };
    if (!confirmNewPassword)
      validationObj.confirmNewPassword = "This is required field.";
    if (!oldPassword) validationObj.oldPassword = "This is required field.";
    if (!newPassword) validationObj.newPassword = "This is required field.";

    setInputsObject({
      ...inputsObject,
      validation: { ...inputsObject.validation, ...validationObj },
    });
  }

  const handleUpdatePassword = () => {
    const payLoad = {
      oldPassword: inputsObject.oldPassword,
      newPassword: inputsObject.newPassword,
    };
    setLoaderState(true);

    const userId = window.piSession.userId();
    updateUserProfile(userId, payLoad).subscribe(
      (response) => {
        toastMessage({
          msg: "<strong>Password</strong> updated Successfully !!!",
          success: true,
        });
      },
      (error) => {
        setInputsObject(initUser);
        setLoaderState(false);
        errorPopup.setState({
          heading: t("Oops!! Something went wrong"),
          subInfo: error?.data?.detail || t("Something went wrong while updating the password"),
          image: `${process.env.PUBLIC_URL}/images/generic-error-icon.svg`,
          btnName: t("Okay, Got it"),
          btnStyle: true,
          buttonAction: () => {
            errorPopup.setVisibility(false)
            setIsModalshow(true)
          }
        })
        errorPopup.setVisibility(true);
      },
      () => {
        setInputsObject(initUser);
        setLoaderState(false);
      }
    );
  };

  return (
    <div className="ogaWrapper__bdy active-hub__password mx-1">
      <ConfirmPopUp
            isModalshow={isModalshow}
            title='Change Password'
            dialogMsg={<>
              <div className="d-flex align-item-center">
                  <div className="col-6 pl-0">
                      <div className="passwordchange__wrapper">
                          <h4 className="passwordchange__heading">Password Guidelines</h4>
                          <ul className="passwordchange__list">
                              <li className="passwordchange__list--item">Must be at least 8 characters</li>
                              <li className="passwordchange__list--item">Must contain characters from at least 3 of the following 4 character groups:
                                  <ul className="passwordchange__sublist">
                                      <li className="passwordchange__sublist--item">
                                          English uppercase characters (A through Z)
                                      </li>
                                      <li className="passwordchange__sublist--item">
                                          English lowercase characters (a through z)
                                      </li>
                                      <li className="passwordchange__sublist--item">
                                          Numerals (0 through 9)
                                      </li>
                                      <li className="passwordchange__sublist--item">
                                          Symbols on your keyboard except £, €, ¤, ¥
                                      </li>
                                  </ul>
                              </li>                                                
                          </ul>
                      </div>
                  </div>
  
                  <div className="vl"></div>
  
                  <div className="col-6">
                      <PasswordInput
                          frmgrpclsname="position-relative"
                          inputType={"password"}
                          title={t("Old Password")}
                          name={"oldPassword"}
                          value={inputsObject.oldPassword}
                          onChange={handleInput}
                          placeholder={t("Type old password")}
                          errorMessage={inputsObject.validation.oldPassword}
                      />
                          
                      <PasswordInput
                          frmgrpclsname="position-relative"
                          inputType={"password"}
                          title={t("New Password")}
                          name={"newPassword"}
                          placeholder={t("Type new password")}
                          onChange={handleInput}
                          value={inputsObject.newPassword}
                          errorMessage={inputsObject.validation.newPassword}
                      />
                      <PasswordInput
                          frmgrpclsname="position-relative confirm-password-field"
                          inputType={"password"}
                          title={t("Confirm New Password")}
                          name={"confirmNewPassword"}
                          placeholder={t("Confirm new password")}
                          onChange={handleInput}
                          value={inputsObject.confirmNewPassword}
                          errorMessage={inputsObject.validation.confirmNewPassword}
                      />
                  </div>
              </div>
          </>}
            positiveButtonText='Submit'
            negativeButtonText='Cancel'
            positiveButtonHandle={()=>updateClickHandler()}
            negativeButtonHandle={()=>setIsModalshow(false)}
            
          />
      <div className="mt-3">
        <div className="">
          <div className="">
            {selectedSpaId === "userProfile" &&
              <UserProfile
                handlePasswordPopupState={setIsModalshow}
              />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWrapper;
